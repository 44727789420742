import React, { lazy, useMemo } from 'react';
import './App.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import Toast from './Components/Toast/Toast';
import PageLoader from './Components/pageLoader/pageLoader';
import HeaderSidebarWrapper from './Components/Sidebar/HeaderSidebarWrapper';
import { createTheme , ThemeProvider } from '@mui/material/styles';
import { themeSettings } from './appTheme/theme';

const lazyWithRetry = (componentImport: any, name: any) => async () => {
	const pageHasAlreadyBeenForceRefreshed = JSON.parse(
		window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
	);

	try {
		const component = await componentImport();

		window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');

		return component;
	} catch (error) {
		if (!pageHasAlreadyBeenForceRefreshed) {
			// Assuming that the user is not on the latest version of the application.
			// Let's refresh the page immediately.
			window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
			return window.location.reload();
		}

		// The page has already been reloaded
		// Assuming that user is already using the latest version of the application.
		// Let's let the application crash and raise the error.
		throw error;
	}
};

const Login = lazy(
	lazyWithRetry(() => import('../src/Containers/Login/Login'), 'login')
);

const EmailListing = lazy(
	lazyWithRetry(() => import('../src/Containers/EmailListing/EmailListing'), 'email-list')
);
const DetailedView = lazy(
	lazyWithRetry(() => import('../src/Containers/DetailedView/DetailedView'), 'email-list')
);
const SendEmail = lazy(
	lazyWithRetry(() => import('../src/Containers/SendEmail/SendEmail'), 'send-email')
);
const exclusionArray = [
	'/',
	"/signin",
	// '/change-password',
  ];
function App() {
	const location = useLocation();
	const isHeaderSidemenuVisible = exclusionArray.indexOf(location.pathname) < 0;
	const theme: any = useMemo(() => createTheme(themeSettings('light')), ['light']);

  return (
	<ThemeProvider
			// theme={getTheme()}
			theme={theme}
		>
	<div
	className={isHeaderSidemenuVisible ? 'app' : ''}
	style={{ backgroundColor: '' }}
>
	{isHeaderSidemenuVisible ? <HeaderSidebarWrapper /> : null}
	<div
		className={
			isHeaderSidemenuVisible
				
					? 'app__outer'
					: 'app__outer app__embeded'
				
		}
	>
      <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/email-listing' element={<EmailListing />} />
	  <Route path='/detailed-view' element={<DetailedView />} />

      <Route path='/send-email' element={<SendEmail />} />
      </Routes>
	  {/* <PageLoader/> */}
	  <Toast/>
    {/* <Login/> */}
	</div>
    </div>
	</ThemeProvider>
  );
}

export default App;
